@media screen and (max-width: 1150px) {
    .game-box{
        height: 170px;
        width: 170px;
        padding: 10px;
    }
    .game-box i{
        font-size: 85px;
    }
}

@media screen and (max-width: 991px) {
    .nav-item a:active::after,
    .nav-item a:focus::after,
    .nav-item a:hover::after {
      content: '';
      position: absolute;
      bottom: 20%;
      left: 50%;
      transform: translateX(-50%);
      width: 90%; 
      height: 2px;
      background-color: white;
    }

    .game-box{
        height: 130px;
        width: 130px;
        padding: 10px;
    }
    .game-box i{
        font-size: 65px;
    }
}

@media screen and (max-width: 768px) {
    .avatar-title {
        padding: 10px;
    }
    h5 {
        font-size: 20px !important; 
     }
     .game-box{
        height: 100px;
        width: 100px;
        padding: 10px;
    }
    .game-box i{
        font-size: 50px;
    }
}

@media screen and (max-width: 576px) {

    h5 {
       font-size: 15px !important; 
    }
    .f-20{
        font-size: 15px;
    }
    .btn{
        font-size: 15px;
    }
    .sub-container{
        overflow-x: scroll;
    }
    .inner-box{
        width: 120px;
    }
}
@media screen and (max-width: 420px) {

    h5 {
       font-size: 14px !important; 
    }
    .btn{
        font-size: 12px;
    }
    .card-body{
        padding: 10px;
    }
    p{
        font-size: 12px;
    }
    .avatar-title{
        padding: 6px;
    }
}



/* as per height css */

/* @media screen and (max-height: 900px) {
    .knifeGame{
        height: 62vh;
    } 
}
@media screen and (max-height: 800px) {
    .knifeGame{
        height: 73vh;
    } 
}
@media screen and (max-height: 700px) {
    .knifeGame{
        height: 80vh;
    } 
}
@media screen and (max-height: 650px) {
    .knifeGame{
        height: 85vh;
    } 
}
@media screen and (max-height: 600px) {
    .knifeGame{
        height: 93vh;
    } 
} */